nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 999;
  padding-top: inRem(20);

  .logo {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    background-color: rgba($white, 0.9);
    height: inRem(160);
    border-radius: 0 inRem(160) inRem(160) 0;
    padding: 0 inRem(24) 0 0;
    transition: height 600ms;

    .social-menu-sub-page {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: inRem(64);
      padding-top: inRem(6);
      margin-right: inRem(16);
      a {
        display: block;
        height: inRem(20);
        width: inRem(20);
        margin-right: inRem(6);
        margin-bottom: inRem(6);

        img {
          height: inRem(20);
        }
      }
      
      @include medium(down) {
        display: none;
      }
    }

    @include medium(down) {
      width: 80%;
    }

    @include xsmall(down) {
      height: inRem(110);
    }

    .logo-wrapper{

    }

    img.logotype {
      height: inRem(124);
      transition: height 600ms;

      @include xsmall(down) {
        height: inRem(80);
      }
    }

    .social {
      display: flex;
      //padding-left: inRem(24);
      padding-right: inRem(24);
      justify-content: flex-end;

      a {
        margin-right: inRem(12);
        img {
          width: inRem(24);
        }
      }
    }
  }


  .nav-menu {

    @include medium(down) {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: inRem(24);
    }

    .burger {
      position: relative;
      width: inRem(50);

      .line {
        height: inRem(5);
        background-color: $white;
        margin-top: inRem(6);
        border-radius: inRem(2);
        &:first-child {
          margin-top: 0;
        }
      }

      @include medium(down) {
        &.active {
          .line {
            opacity: 0;
          }
          a {
            &:before,
            &:after {
              content: '';
              position: absolute;
              width: inRem(32);
              height: inRem(4);
              background-color: $white;
              top: inRem(8);
            }

            &:before {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              transform: rotate(45deg);
              left: inRem(9);
            }

            &:after {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              transform: rotate(-45deg);
              right: inRem(9);
            }
          }
        }
      }

      a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }



      @include medium(under) {
        display: none;
      }
    }

    .top-menu {

    }

    @include medium(under) {
      .top-menu {
        display: block;
        ul {
          display: flex;

          li {
            margin-left: inRem(24);

            a {
              @include fontNillandBlack(inRem(15));
              color: $white;
              text-transform: uppercase;
              letter-spacing: inRem(2);
              text-decoration: none;

              &.active {
                //color: $pink;
                position: relative;

                &:after {
                  content: "";
                  display: block;
                  position: absolute;
                  left: inRem(8, minus);
                  bottom: inRem(6, minus);
                  width: calc(100% + #{inRem(16)});
                  height: inRem(2);
                  background-color: $white;
                }
              }
            }
          }
        }
      }

      .mobile-social {
        display: none;
      }
    }

    @include medium(down) {
      .top-menu {
        position: fixed;
        left: 0;
        top: -200vh;
        height: 0;
        width: 100%;
        opacity: 0;


        &.active {
          top: inRem(64);
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          background-color: rgba($white, 0.98);
          opacity: 1;
          transition: 400ms top, 400ms height, 800ms opacity;

          ul {
            padding-right: inRem(60);
            padding-top: inRem(16);

            li {
              margin-bottom: inRem(16);
              a {
                @include fontNillandBlack(inRem(15));
                color: $brown;
                text-transform: uppercase;
                letter-spacing: inRem(2);
                text-decoration: none;
              }
            }
          }
        }
      }

      .mobile-social {
        display: none;

        &.active {
          display: flex;
          padding-right: inRem(88);

          a {
            margin-right: inRem(12);

            img {
              width: inRem(24);
            }
          }
        }
      }
    }
  }

  &.out-of-header, &.out-of-header-mobile {
    background-color: $blue;
    padding-top: inRem(0);
    transition: padding-top 300ms;

    .logo{
      height: inRem(64);
      transition: height 600ms;
      img {
        height: inRem(64);
        transition: height 600ms;
      }

      .social{
        display: none;
      }
    }

    .nav-menu {
      display: flex;
      align-items: center;
    }
  }
}
